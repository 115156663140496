<template>
  <div class="service-depend-canvas" ref="container" v-loading="loading">
    <dependency-graph :data="data" />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { getServiceDepend } from '@/api/repository';
import { getAppDepend } from '@/api/app';
import DependencyGraph from './Graph.vue';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'ServiceDependency',
  components: {
    DependencyGraph,
  },
  setup() {
    const loading = ref(true);
    const data = ref([]);

    const fetchData = async (name, version, type = 'service') => {
      loading.value = true;
      const api = type === 'app' ? getAppDepend : getServiceDepend;
      try {
        console.log('请求参数', api, name, version);
        const { code, data: resData } = await api({
          name,
          version,
        });
        if (code === 0) {
          data.value = resData;
        }
      } catch (e) {
        ElMessage.error('获取依赖失败');
      }
      loading.value = false;
    };

    const render = (name, version, type = 'service') => {
      console.log('type', type);
      fetchData(name, version, type);
    };

    return {
      loading,
      data,
      render,
    };
  },
});
</script>
<style lang="scss" scoped>
.service-depend-canvas {
  width: 100%;
  height: 100%;
  user-select: none;
  overflow: hidden;
  user-select: none;
}
.render-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.g6-component-toolbar {
  li {
    &[code='redo'],
    &[code='undo'] {
      display: none !important;
    }
  }
}
.g6-minimap {
  position: absolute;
  bottom: 20px;
  right: 20px;
  user-select: none;
  .g6-minimap-viewport {
    border: rgb(25, 128, 255) solid 2px;
    outline: none !important;
    cursor: move;
  }
}
.g6-component-toolbar {
  top: 20px !important;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 11;
  cursor: pointer;
}
</style>
