<template>
  <div class="dependency" @click="removeActive" ref="wrapRef">
    <svg class="dependency-graph" :width="graph.width + svgPadding * 2" :height="svgHeight">
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="9"
          refY="5"
          markerUnits="strokeWidth"
          markerWidth="8"
          markerHeight="6"
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 L 4 5 z" style="fill: #7e868e"></path>
        </marker>
      </defs>
      <g class="dependency-nodes">
        <g class="dependency-node" v-for="n in graph.nodes" :key="n.key">
          <rect
            :id="`dep-node-${n.key}`"
            class="dependency-node__rect"
            :width="n.width"
            :height="n.height"
            :x="n.x - n.width / 2"
            :y="n.y - n.height / 2"
            fill="#fff"
            stroke-width="0"
          />
          <rect
            class="dependency-node__icon"
            :width="n.height"
            :height="n.height"
            :x="n.x - n.width / 2"
            :y="n.y - n.height / 2"
            fill="#a0cfff"
            stroke-width="0"
          />
          <image
            :href="icon"
            width="16"
            height="16"
            stroke-width="1"
            stroke-opacity="1"
            fill-opacity="1"
            :x="n.x - n.width / 2 + (n.height - 16) / 2"
            :y="n.y - n.height / 2 + (n.height - 16) / 2"
          />
          <text
            class="dependency-node__label"
            alignment-baseline="after-edge"
            text-anchor="left"
            opacity="0.7"
            stroke-width="1"
            stroke-opacity="1"
            fill-opacity="1"
            :x="n.x - n.width / 2 + n.height + 10"
            :y="n.y + 2"
            font-size="12"
            font-family="sans-serif"
            font-style="normal"
            font-weight="normal"
            font-variant="normal"
            fill="#000"
            paint-order="stroke"
            style="stroke-linecap: butt; stroke-linejoin: miter"
            stroke="none"
          >
            {{ n.showName }}
          </text>
          <text
            class="dependency-node__label"
            alignment-baseline="after-edge"
            text-anchor="left"
            opacity="0.7"
            stroke-width="1"
            stroke-opacity="1"
            fill-opacity="1"
            :x="n.x - n.width / 2 + n.height + 10"
            :y="n.y + 14"
            font-size="10"
            font-family="sans-serif"
            font-style="normal"
            font-weight="normal"
            font-variant="normal"
            fill="#000"
            paint-order="stroke"
            style="stroke-linecap: butt; stroke-linejoin: miter"
            stroke="none"
          >
            {{ n.version }}
          </text>
          <rect
            class="dependency-node__rect_mask"
            :width="n.width"
            :height="n.height"
            :x="n.x - n.width / 2"
            :y="n.y - n.height / 2"
            fill="rgba(0,0,0,0)"
            stroke-width="1"
            :stroke="n.key === activeNode ? '#006eff' : '#CED4D9'"
            @click.stop="toggleActive(n)"
            @mouseover="showTooltip(n)"
            @mouseleave="hideTooltip"
          />
        </g>
      </g>
      <g class="dependency-edges">
        <g class="dependency-edge" v-for="(e, index) in graph.edges" :key="index">
          <path
            :id="`dep-edge-${e.v}-${e.w}`"
            stroke-width="1"
            fill="none"
            stroke="#CED4D9"
            marker-end="url(#arrow)"
            :d="getPathD(e)"
          ></path>
          <circle r="3" fill="#006eff" v-if="activeNode === e.v">
            <animateMotion :dur="`${e.points.length / 3}s`" :path="getPathD(e)" repeatCount="indefinite">
              <mpath :xlink:href="`#dep-edge-${e.v}-${e.w}`" />
            </animateMotion>
          </circle>
        </g>
      </g>
    </svg>
    <div
      class="svg-node-tooltip"
      :style="tooltipStyle"
      :class="tooltipVisible ? 'is-show' : ''"
      v-show="tooltipVisible"
    >
      {{ tooltipContent }}
    </div>
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
import { nextTick, onMounted, reactive, ref, watch, computed } from 'vue';
import icon from './icon.svg';
import dagre from 'dagre';
const NODE_WIDTH = 160;
const NODE_HEIGHT = 44;
const SVG_PADDING = 20;
export default {
  name: 'DependencyGraph',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const graph = reactive({
      nodes: [],
      edges: [],
      width: 0,
      height: 0,
    });
    const activeNode = ref('');
    const wrapRef = ref();
    const graphInstance = ref();

    // 布局
    const layout = () => {
      if (props.data.length === 0) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeActive();
      graphInstance.value && (graphInstance.value = null);
      graphInstance.value = new dagre.graphlib.Graph();
      const g = graphInstance.value;
      g.setGraph({
        rankdir: 'LR',
        ranksep: 60,
        nodesep: 100,
      });
      g.setDefaultEdgeLabel(() => ({}));
      graph.nodes = [];
      graph.edges = [];
      g.edges().forEach((n) => g.removeEdge(n.key));
      g.nodes().forEach((n) => g.removeNode(n.key));

      const data = [...props.data];

      data.forEach((node) => {
        const key = `${node.name}@${node.version}`;
        const depKey = node.dependencyName ? `${node.dependencyName}@${node.dependencyVersion}` : null;
        const showName = node.name.split('.')[3];
        node.key = key;
        node.depKey = depKey;
        node.showName = showName.length > 13 ? `${showName.slice(0, 13)}...` : showName;
        g.setNode(node.key, {
          ...node,
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        });
        if (node.depKey) {
          g.setEdge(node.key, node.depKey);
        }
      });

      dagre.layout(g);

      const edges = g.edges();
      graph.nodes = g
        .nodes()
        .map((n) => g.node(n))
        .filter((n) => n);
      graph.edges = edges.map((e) => ({
        ...e,
        ...g.edge(e),
      }));

      const maxEdgeX = Math.max(...edges.map((e) => Math.max(...g.edge(e).points.map((p) => p.x))));
      const maxEdgeY = Math.max(...edges.map((e) => Math.max(...g.edge(e).points.map((p) => p.y))));
      graph.width = Math.max(g.graph().width, maxEdgeX + 1);
      graph.height = Math.max(g.graph().height, maxEdgeY + 1);
    };

    const getPathD = (e) => `M ${e.points.map((p) => `${p.x},${p.y}`).join(' ')}`;

    const toggleActive = (n) => {
      activeNode.value = n.key;
    };

    const removeActive = () => (activeNode.value = null);

    const svgHeight = computed(() => graph.height + SVG_PADDING * 2);
    const tooltipVisible = ref(false);
    const tooltipContent = ref('');
    const tooltipStyle = reactive({
      top: 0,
      left: 0,
    });

    const showTooltip = (n) => {
      const wrapWidth = wrapRef.value.offsetWidth;
      const wrapHeight = wrapRef.value.offsetHeight;
      tooltipStyle.top = `${wrapHeight / 2 - svgHeight.value / 2 + n.y - n.height - 40}px`;
      tooltipStyle.left = `${(wrapWidth - graph.width) / 2 + n.x - n.width / 2 - 20}px`;
      tooltipContent.value = `${n.key}`;
      tooltipVisible.value = true;
    };

    const hideTooltip = () => {
      tooltipContent.value = '';
      tooltipVisible.value = false;
    };

    onMounted(() => {
      nextTick(() => layout());
    });

    watch(
      () => props.data,
      () => {
        layout();
      },
    );

    return {
      icon,
      graph,
      svgPadding: SVG_PADDING,
      activeNode,
      svgHeight,
      wrapRef,
      getPathD,
      toggleActive,
      removeActive,
      tooltipVisible,
      tooltipStyle,
      tooltipContent,
      showTooltip,
      hideTooltip,
    };
  },
};
</script>
<style lang="scss" scoped>
.dependency {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  overflow: auto;
  padding: 20px;
  &::before,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    content: '';
    height: 100%;
  }

  .dependency-node {
    cursor: pointer;
  }

  svg {
    overflow: visible;
  }
}
.svg-node-tooltip {
  background-color: rgba($color: #000000, $alpha: 0.7);
  font-size: 12px;
  color: rgba($color: #fff, $alpha: 0.9);
  padding: 10px 10px;
  max-width: 420px;
  line-height: 20px;
  display: inline-block;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: left;
  &.is-show {
    width: unset;
    height: unset;
    opacity: 1;
  }
}
@keyframes act {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
